.page-content.map-view {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  background-color: black;
  overflow: hidden;
}

#image-preloader {
  background-image: url(./assets/radar_bg.png);
  opacity: 0;
  pointer-events: none;
}

#image-preloader2 {
  background-image: url(./assets/radar.svg);
  opacity: 0;
  pointer-events: none;
}

.radar {
  opacity: 1;
  background-image: url('https://underattack.saysource.com/assets/radar_map.jpg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin: auto;
  overflow: hidden;
  top: 0px;
}

.radar-container {
  top: calc(7px + env(safe-area-inset-top));
  overflow: hidden;
  margin: auto;
  position: relative;
}


.radar .pointer {
  position: absolute;
  left: -200px;
  right: -200px;
  top: -200px;
  bottom: -200px;
  will-change: transform;
  /* transform-origin: 50% 100%; */
  /* border-radius: 50% 50% 0 0 / 100% 100% 0 0; */

  background-size: 200%;
  background-position: center center;

  /* background-image: linear-gradient(135deg, 
    rgba(5, 185, 26, 0.8) 0%, 
    rgba(0, 0, 0, 0.02) 70%,
    rgba(0, 0, 0, 1) 100%
    );
  clip-path: polygon(100% 0, 
    100% 10%, 
    50% 100%,
    0 100%, 0 0); */
  background-image: url(./assets/radar.svg);
  background-size: 200%;
  animation: rotate360 3s infinite linear;
  pointer-events: none;
}

/* .radar::after {
  content: '';
  background-image: url(./assets/radar_clip.svg);
  background-size: cover;
  position: absolute;
  top: -2px;
  right: 0;
  bottom: -2px;
  left: 0;
} */

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}


.map-view-ui {
  position: absolute;
  left: 0;
  right: 0;
  top: env(safe-area-inset-top);
  bottom: 0;
  background-image: url(./assets/radar_bg.png);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}

@keyframes flicker {
  0% {
    opacity: 0.80455;
  }
  5% {
    opacity: 0.9858;
  }
  10% {
    opacity: 0.74535;
  }
  15% {
    opacity: 0.91526;
  }
  20% {
    opacity: 0.84396;
  }
  25% {
    opacity: 0.72365;
  }
  30% {
    opacity: 0.81087;
  }
  35% {
    opacity: 0.75631;
  }
  40% {
    opacity: 0.62436;
  }
  45% {
    opacity: 0.97067;
  }
  50% {
    opacity: 0.67494;
  }
  55% {
    opacity: 0.67039;
  }
  60% {
    opacity: 0.71643;
  }
  65% {
    opacity: 0.68811;
  }
  70% {
    opacity: 0.78043;
  }
  75% {
    opacity: 0.91575;
  }
  80% {
    opacity: 0.82374;
  }
  85% {
    opacity: 0.84919;
  }
  90% {
    opacity: 0.85861;
  }
  95% {
    opacity: 0.42979;
  }
  100% {
    opacity: 0.93588;
  }
}

@keyframes turning-on {
  0%{
    transform:scale(1,0.8) translate3d(0,0,0);
    -webkit-filter:brightness(30);
    filter:brightness(30);
    opacity:1;
  }
  35%{
    transform:scale(1,0.8) translate3d(0,100%,0);
  }
  
  36%{
    transform:scale(1,0.8) translate3d(0,-100%,0);
    opacity:1;
  } 
  
  80%{
    transform:scale(1.3,0.6) translate3d(0,100%,0);
    -webkit-filter:brightness(30);
    filter:brightness(30);
    opacity:0.4;
  }
  
  90%{
    transform:scale(1,1) translate3d(0,0,0);
    -webkit-filter:contrast(0) brightness(0) ;
    filter:contrast(0) brightness(0);
    opacity:0.4;
  }
  
  100%{
    transform:scale(1,1) translate3d(0,0,0);
    -webkit-filter:contrast(1) brightness(1.2) saturate(1.3);
    filter:contrast(1) brightness(1.2) saturate(1.3);
    opacity:0.5;
  }
}

.radar.flickering {
  animation: flicker 0.15s;
}

.radar.turning-on {
  opacity: 0;
  animation: turning-on 500ms 1000ms linear;
}

.radar.turned-off {
  opacity: 0.01;
}

.radar .pointer {
  opacity: 1;
}

.radar.turing-off .pointer,
.radar.turned-off .pointer {
  opacity: 0;
}



.radar.turning-off {
  opacity: 1;
  animation: turning-off 350ms linear, turned-off 500ms linear;
}

@keyframes turning-off{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes turned-on {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes turning-off{
  0%{
    transform:scale(1,1.3) translate3d(0,0,0);
    -webkit-filter:brightness(1);
    filter:brightness(1);
    opacity:1;
  }
  60%{
    transform:scale(1.3,0.001) translate3d(0,0,0);
    -webkit-filter:brightness(10);
    filter:brightness(10);
  }
  100%{
    animation-timing-function: ease-in-out;
    transform: scale(0.000,0.0001) translate3d(0,0,0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}

.radar-target {
  position: absolute;
  width: 10px;
  height: 12px;
  border-radius: 50%;
  background-color: #e7ffc2;
  box-shadow: 0px 0px 5px #aaff2a;
  animation: fade-in-out 3s infinite;
  opacity: 0;
}


@keyframes fade-in-out {
  0% { opacity: 1; transform: scale(1,1); }
  30% { opacity: 1; transform: scale(1,1); }
  100% { opacity: 0.01; transform: scale(0.8,0.8); }
}