

.page-content.image-tile-view {
  position: absolute;
  top: 0px;
  /* bottom: 0px; */
  left: 0px;
  width: 100vw;
  height: 100vh;
  color: white;
  padding: 0px 0px;
  overflow: hidden;
  background-color: rgba(255,0,0,0.2);
}

.page-content.image-tile-view #tile-view-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  border: 1px solid red;
}

#image-tile-view-code-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200px;
    color: red;
    line-height: 100px;
    white-space: nowrap;
    transform: rotate(-90deg);
}

#image-tile-view-code-overlay.animated {
  animation: codeAnimation 2s infinite;
}

@keyframes codeAnimation {
  0% { opacity: 0; }
  15% { opacity: 1; }
  85% { opacity: 1; }
  100% { opacity: 0; }
  
}


.tile-fullcode {
  font-size: 80px;
  color: greenyellow;
  text-shadow: 0px 0px 4px black;
}