
/* :root {
  color-scheme: only dark;
  background-color: black;
} */

body {
  margin: 0;
  font-family: 'Orbitron' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: black;
}

.ios-peek {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  background-image: url('./assets/circuits.jpg');
  background-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#scene_container {
  padding-top: 100px;
}

#scene_container canvas {
  opacity: 1;
}

.page {
  position: absolute;
  top: 0;
  right: 0; /*-100%;*/
  opacity: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: right 250ms ease-in-out, opacity 250ms ease-in-out, transform 250ms ease-in-out,;
  /* background: linear-gradient(0deg, black, transparent); */
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgb(20 30 52) 66%, rgba(0,0,0,0) 100%);
  transform: scale(0.1);
  background-image: url('./assets/blueprint_2.png');
  background-position: top center;
}

.page:not(.page-in) {
  pointer-events: none;
}

.page-in {
  right: 0;
  opacity: 1;
  transform: scale(1);
}

.page-content {
  position: absolute;
  top: 100px;
  bottom: 40px;
  left: 0px;
  right: 0px;
  height: calc(100vh - 140px);
  color: white;
  padding: 10px 10px;
  overflow: scroll;
}

.page-content .title {
  font-weight: 900;
  margin-bottom: 30px;
}

.page-content p {
  text-align: left;
  font-size: 18px;
}



.back_btn {
  position: absolute;
  top: calc(10px + env(safe-area-inset-top));
  left: 10px;
  color: white;
}

.gesture {
  position: absolute;
  display: block;
  bottom: calc(20px + env(safe-area-inset-bottom));
  right: 20px;
  width: 64px;
  height: 64px;
  background-image: url('./assets/gesture.svg');
  background-size: cover;
  color: white;
}


.AppBackground1,
.AppBackground2,
.AppContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
}

.AppBackground1 {
  padding-top: 40px;
  background-color: black;
}

.AppBackground2 {
  opacity: 0.4;
  background-image: url('./assets/blueprint.jpg');
  background-position: center center;
}



@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;900&display=swap');




img {
  max-width: 100%;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: black;
  text-align: center;
  background-image: url('./assets/app_bg.jpg');
  background-size: cover;
  touch-action: initial;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Home {
  position: absolute;
  bottom: calc(env(safe-area-inset-bottom) + 20px);
  left: 0px;
  right: 0px;
}

.ua-btn {
  display: block;
  border: 1px solid #54a6f4;
  background: linear-gradient(180deg, #000414, #012149);
  border-radius: 5px;
  padding: 10px;
  color: #54a6f4;
  box-shadow: 1px 1px 10px #54a6f4;
  text-decoration: none;
  text-shadow: 1px 1px 20px #54a6f4;
  font-family: 'Orbitron', cursive;
  font-size: 18px;
}

.ua-disabled {
  opacity: 0.6;
  box-shadow: none;
}

.ua-btns {
  margin: 0px;
  padding: 10px;
  width: 100%;
  list-style: none;
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.ua-btns li {
  flex-grow: 1;
  margin: 5px;
}

h2, p {
  color: white;
}

.loading,
.no-access {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ffbcbc;
  text-shadow: 0 0 20px white;
  animation-name: no-access-anim;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
}

.loading {
  color: #bef8af;
}

@keyframes no-access-anim {
  0% {color: #ffbcbc;}
  50% {color: red;}
  100% {color: #ffbcbc;}
}


@keyframes loading-anim {
  0% {color: rgba(0, 255, 0, 0.3);}
  50% {color: rgb(0, 255, 0);}
  100% {color: rgba(0, 255, 0, 0.3);}
}

.fading-green {
  font-size: 20px;
  text-shadow: 0 0 20px white;
  display: block;
  margin: 20px 0px;
  text-align: center;
  animation-name: loading-anim;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
}

p {
  color: white;
  
}

.mission-btn {
  padding: 16px 10px;
  background-color: #0e4c7c9e;
  width: 100%;
  margin-bottom: 4px;
  box-shadow: 2px 2px 5px #306f99;
}

.fw {
  width: 100%;
}

.ua-label {
  display: block;
  font-weight: 900;
  margin-bottom: 5px;
  margin-top: 20px;
}

.App .page-content hr {
  border-bottom: 1px solid white !important;
  opacity: 1;
}

.mission-solve-help {
  font-size: 14px;
  color: #ffda00;
  margin-top: 10px;
  display: inline-block;
}

.mission-solve-error {
  font-size: 16px;
  color: #ff0000;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
}

.ua-input-text.form-control {
  background-color: #315480;
  border: #012149;
  color: white;
  text-shadow: 0 0 2px white;
}

.ua-input-text {
  color: white;
  background-color: #7897ce78;
  max-width: 100%;
  font-size: 30px;
  text-align: center;
  border: 1px solid white;
  border-radius: 3px;
  padding: 10px;
  outline: none;
}



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CCCCCC;
  opacity: 0.8; /* Firefox */
  font-size: 20px;
  line-height: 24px;
}

.App li {
  position: relative;
}

.mission-locked-icon,
.mission-unlocked-icon,
.mission-completed-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 9px;
  color: rgb(0, 255, 0);
}

.mission-unlocked-icon,
.mission-locked-icon {
  color: #54a6f4;
  right: 14px;
}

.mission-unlocked-icon {
  right: 9px;
}

.mission-unlocked-icon svg,
.mission-locked-icon svg,
.mission-completed-icon svg {
  width: 100%;
  height: 100%;
}

.qreader {
  width: 90%;
  overflow: hidden;
  position: relative;
  height: auto;
  border-radius: 40px;
  margin: auto;
  border: 2px solid #27668c;
  background-color: #121e39;
  box-shadow: 0 0 15px #77b9e3;
}

.qreader video {
  max-height: calc(100vh - 200px);
}

.qreader::after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/target.svg');
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
}

.agent-info img {
  max-width: 90%;
}

.rfid-icon {
  max-width: 100px;
  margin: auto;
  margin-top: 200px;
}

.rfid-icon svg path {
  fill: white;
}

#webcamview {
  /* margin-top: 182px; */
}

#webcamview video {
  border: 1px dashed #355f7d;
  opacity: 0.5;
}

.webcamwrapper {
  position: relative;
}

.webcamwrapper canvas {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); 
}

.webcampage {
  position: absolute;
  padding: 10px;
  bottom: 0px;
}


.page-content.geiger-counter {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
  background-image: url('./assets/geiger_counter.png');
  background-position: center env(safe-area-inset-top);
  background-repeat: no-repeat;
  overflow: hidden;
}




#needle {
  position: absolute;
    top: calc(90px + env(safe-area-inset-top));
    left: 50%;
    height: 150px;
    border-left: 2px solid #2a2a20;
    transform-origin: center bottom;
    transform: rotate(-45deg);
    box-shadow: -4px 0px 3px #777;
    transition: transform 100ms ease-in-out;
}

.webcampage {
    position: absolute;
    padding: 10px;
    top: 0px;
}

.webcamwrapper {
  opacity: 0;
}

#geiger_counter_color {
    width: 52px;
    height: 51px;
    position: absolute;
    top: 749px;
    left: 11px;
    opacity: 0.5;
    /* border: 1px solid white; */
    border-radius: 50%;
    left: 50%;
    transform: translate(-175px, 0px);
}


.ua-solve-code {
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out;
}

.invalid-solve-code {
  background-color: #ff000070;
  border: 1px solid red;
  color: red;
  animation-name: bounce;
  animation-duration: .5s;
  animation-delay: 0.25s;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}


.page-light .back_btn {
  color: black;
}

.ol-list li {
  margin-bottom: 20px;
}

.grid-view {
  background-color: black;
  color: white;
  font-size: 20px;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding: 0px;
  display: flex;
}

.flex-center-content {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.ua-dialog {
  position: absolute;
  top: 20%;
  width: 90%;
  left: 5%;
  background-color: rgb(30 51 97);
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid #54a6f4;
  box-shadow: 1px 1px 10px #000000;
  color: white;
  background-image: url('./assets/blueprint.jpg');
  background-position: center center;
  background-size: 150%;
  transition: opacity 500ms, transform 500ms;
}

.ua-dialog.ua-alert {
  background-image: url('./assets/blueprint_red.png');
  border: 1px solid #ff0000;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ua-dialog.ua-success {
  background-image: url('./assets/blueprint_green.png');
  border: 1px solid #006a12;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ua-candystripe {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 20px;
  left: 0px;
  border-radius: 10px 10px 0px 0px;
  /* background-image: repeating-linear-gradient( 45deg, #ff0000a6, #c2000066 10px, #00000057 10px, #00000075 20px ); */
  /* background-size: 1000px 20px; */
}

.ua-candystripe::after {
  content: "";
  position: absolute;
  top: 0px; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg, 
    rgba(255, 255, 255, 0.2) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, 0.2) 50%, 
    rgba(255, 255, 255, 0.2) 75%, 
    transparent 75%, 
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  animation: candistripe-animation 2s infinite linear;
}

.ua-candystripe-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 20px;
  left: 0px;
  border-radius: 10px 10px 0px 0px;
}

.ua-candystripe-bottom::after {
  content: "";
  position: absolute;
  top: 0px; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg, 
    rgba(255, 255, 255, 0.2) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, 0.2) 50%, 
    rgba(255, 255, 255, 0.2) 75%, 
    transparent 75%, 
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  animation: candistripe-animation 2s infinite linear;
}


@keyframes candistripe-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 0px;
  }
}



.ua-alert .ua-btn {
  border: 1px solid #ff0000;
  background: linear-gradient(180deg, #590000, #490101);
  color: #ff4646;
  box-shadow: 1px 1px 10px #ff0000;
  text-shadow: 1px 1px 20px #ff0000;
}

.ua-success .ua-btn {
  border: 1px solid #47b880;
  background: linear-gradient(180deg, #037631, #1b5435);
  color: #25f88e;
  box-shadow: 1px 1px 10px #85e2b8;
  text-shadow: 1px 1px 20px #adfcce;
}

.ua-dialog-backdrop {
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.ua-dialog-exit {
  pointer-events: none;
}

.ua-dialog-body {
  padding: 10px 0px 20px 0px;
}



.ua-dialog-enter .ua-dialog-backdrop {
  opacity: 0;
}

.ua-dialog-enter-active .ua-dialog-backdrop {
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}

.ua-dialog-exit .ua-dialog-backdrop {
  opacity: 1;
}
.ua-dialog-exit-active .ua-dialog-backdrop {
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}


.ua-dialog-enter .ua-dialog {
  opacity: 0;
  animation: bounceIn 500ms;
}

.ua-dialog-enter-active .ua-dialog {
  opacity: 1;
}

.ua-dialog-exit .ua-dialog {
  opacity: 1;
}
.ua-dialog-exit-active .ua-dialog {
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}


/*
.ua-dialog-enter .ua-dialog {
  opacity: 0;
  transform: scale(0.9);
}
.ua-dialog-enter-active .ua-dialog {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.ua-dialog-exit .ua-dialog {
  opacity: 1;
}
.ua-dialog-exit-active .ua-dialog {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}
*/

.ua-dialog-icon {
  max-width: 150px;
  display: block;
  margin: 20px auto;
}


@keyframes bounceIn{
  /* 0%{
    opacity: 0;
    transform: scale(0.3);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  } */

  0%   { opacity: 0;   transform: scale(1,1)      translateY(-100); }
  10%  { opacity: 0.3;   transform: scale(1.1,.9)   translateY(0); }
  30%  { opacity: 0.9;   transform: scale(.9,1.1)   translateY(0px); }
  50%  { opacity: 1; transform: scale(1.05,.95) translateY(0); }
  57%  { opacity: 1;   transform: scale(1,1)      translateY(-7px); }
  64%  { opacity: 1;   transform: scale(1,1)      translateY(0); }
  100% { opacity: 1;   transform: scale(1,1)      translateY(0); }

}

html {
  background-color: black;
}

.text-left {
  text-align: left;;
}

.ua-btns li.clue-item {
  display: blocl;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.code {
    font-size: 1.2em;
    word-wrap: break-word;
    font-weight: bold;
    color: #f58585;
}

.mission-text {
    text-align: left;
}
.mission-text img {
  display: block;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mission-text .text-big {
  font-size: 2rem;
}

.mission-text .text-normal {
  font-size: 1rem;
}

.mission-text .text-medium {
  font-size: 1.5rem;
}

.mission-text .two-cols {
  display: flex;
  align-items: top;
  flex: 1 1;
  border-bottom: 1px solid #dedede;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.mission-text .two-cols div:first-child {
  text-align: left;
  line-height: 22px;
}

.mission-text .two-cols div:last-child {
  text-align: right;
  flex-grow: 1;
  color: #41cf62;
  align-self: flex-end;
}

.mission-text .text-center {
  margin-top: 10px; margin-bottom: 10px; text-align: center
}


.two-parts-code {
  margin: auto;
  display: block;
  text-align: center;
  font-size: 2rem;
}

.two-parts-code span:first-child {
  color: violet;
  background-color: #666;
  padding: 4px 10px;
  border: 1px solid white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
}

.two-parts-code span:last-child {
  color: #41cf62;
  background-color: #666;
  padding: 4px 10px;
  border: 1px solid white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: bold;
}