
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.page-content.page-wargame {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
}


#wargames {
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJAAAD0ElEQVR4Ae3WsQ2AMBDFUCAZAFGw/6KREDvE3bsBXFiW7p/38x6Ogd0Grt1APAZ+A8LSQWJAWIlW0DnGYIGB7QbmWms7FJABr1ADiQFhJVpBhaWBxIDxnmgFNd41kBjwChOtoMLSQGJAWIlWUONdA4kB4z3RCuoVaiAxIKxEK6iwNJAYMN4TraDGuwYSA15hohVUWBpIDAgr0QpqvGsgMWC8J1pBvUINJAaElWgFFZYGEgPGe6IV1HjXQGLAK0y0ggpLA4kBYSVaQY13DSQGjPdEK6hXqIHEgLASraDC0kBiwHhPtIIa7xpIDHiFiVZQYWkgMSCsRCuo8a6BxIDxnmgF9Qo1kBgQVqIVVFgaSAwY74lWUONdA4kBrzDRCiosDSQGhJVoBTXeNZAYMN4TraBeoQYSA8JKtIIKSwOJAeM90QpqvGsgMeAVJlpBhaWBxICwEq2gxrsGEgPGe6IV1CvUQGJAWIlWUGFpIDFgvCdaQY13DSQGvMJEK6iwNJAYEFaiFdR410BiwHhPtIJ6hRpIDAgr0QoqLA0kBoz3RCuo8a6BxIBXmGgFFZYGEgPCSrSCGu8aSAwY74lWUK9QA4kBYSVaQYWlgcSA8Z5oBTXeNZAY8AoTraDC0kBiQFiJVlDjXQOJAeM90QrqFWogMSCsRCuosDSQGDDeE62gxrsGEgNeYaIVVFgaSAwIK9EKarxrIDFgvCdaQb1CDSQGhJVoBRWWBhIDxnuiFdR410BiwCtMtIIKSwOJAWElWkGNdw0kBoz3RCuoV6iBxICwEq2gwtJAYsB4T7SCGu8aSAx4hYlWUGFpIDEgrEQrqPGugcSA8Z5oBfUKNZAYEFaiFVRYGkgMGO+JVlDjXQOJAa8w0QoqLA0kBoSVaAU13jWQGDDeE62gXqEGEgPCSrSCCksDiQHjPdEKarxrIDHgFSZaQYWlgcSAsBKtoMa7BhIDxnuiFdQr1EBiQFiJVlBhaSAxYLwnWkGNdw0kBrzCRCuosDSQGBBWohXUeNdAYsB4T7SCeoUaSAwIK9EKKiwNJAaM90QrqPGugcSAV5hoBRWWBhIDwkq0ghrvGkgMGO+JVlCvUAOJAWElWkGFpYHEgPGeaAU13jWQGPAKE62gwtJAYkBYiVZQ410DiQHjPdEK6hVqIDEgrEQrqLA0kBgw3hOtoMa7BhIDXmGiFVRYGkgMCCvRCmq8ayAxYLwnWkG9Qg0kBoSVaAUVlgYSA8Z7ohXUeNdAYsArTLSCCksDiQFhJVpBjXcNJAaM90QrqFeogcSAsBKtoMLSQGLAeE+0gn6NXXdvK2/RcgAAAABJRU5ErkJggg==);
  /*Noise*/
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 5px 5px 5px;
  text-align: left;
  flex-grow: 1;
  display: flex;
  margin-top: 30px
}

#wargames:after {
  content: "";
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: -15px;
  left: 0;
}

#wargames pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */

  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-filter: blur(0.3px);
  border-left: 5px solid #1b1b1b;
  border-right: 5px solid #1c1c1c;
  border-top: 5px solid #0f1310;
  border-bottom: 5px solid #0f1310;
  overflow: hidden;
  position: relative;
  color: #7cb4fc;
  padding: 10px;
  -webkit-box-shadow: inset 0 0 0 9px rgba(21, 21, 20, 0.6), 0 1px 60px 10px rgba(40, 46, 40, 9), inset 0 1px 80px 5px rgba(0, 0, 0, 0.97);
  -moz-box-shadow: inset 0 0 0 9px rgba(21, 21, 20, 0.6), 0 1px 60px 10px rgba(40, 46, 40, 9), inset 0 1px 80px 5px rgba(0, 0, 0, 0.97);
  box-shadow: inset 0 0 0 9px rgba(21, 21, 20, 0.6), 0 1px 60px 10px rgba(40, 46, 40, 9), inset 0 1px 80px 5px rgba(0, 0, 0, 0.97);
  background-color: #162030;
  text-shadow: #89d3fd 0 -1px 35px;
  flex-grow: 1;
}

#wargames pre:before {
  content: "";
  width: 100%;
  height: 20%;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(40, 66, 25, 0.2)), color-stop(70%, rgba(32, 57, 17, 0)), color-stop(99%, rgba(28, 53, 13, 0)));
  -webkit-animation: scan 6.5s 1.0s infinite ease-in-out forwards;
  -moz-animation: scan 6.5s 1.0s infinite ease-in-out forwards;
  -ms-animation: scan 6.5s 1.0s infinite ease-in-out forwards;
  -o-animation: scan 6.5s 1.0s infinite ease-in-out forwards;
  animation: scan 6.5s 1.0s infinite ease-in-out forwards;
}


#wargames code {
  background: #fff;
  border: 1px solid #DDD;
  padding: 0.1em 0.2em;
}

#wargames pre code {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  border-style: none;
  font: 400 23px VT323, monospace;
}

#wargames pre code:last-child:after {
  content: "\220E";
  -webkit-animation: cursor .95s ease-in-out infinite;
  -moz-animation: cursor .95s ease-in-out infinite;
  -ms-animation: cursor .95s ease-in-out infinite;
  -o-animation: cursor .95s ease-in-out infinite;
  animation: cursor .95s ease-in-out infinite;
  font-size: 120%;
}

/*Animations*/

/* Cant' get "Scan" to work in Firefox*/
@-webkit-keyframes scan {
  from {
    top: -50%;
    left: 0;
  }

  to {
    top: 150%;
    left: 0;
  }
}


@keyframes scan {
  from {
    top: -50%;
    left: 0;
  }

  to {
    top: 150%;
    left: 0;
  }
}


@-webkit-keyframes cursor {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}


@-moz-keyframes cursor {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}


@-ms-keyframes cursor {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}


@-o-keyframes cursor {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}


@keyframes cursor {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}


#wargames_keyboard {
  width: 100%;
  height: 86px;
  background-image: url('./assets/wargame_keyboard.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  display: flex;
  margin-bottom: 20px
}

#wargames_keyboard .btn {
  /* border: 1px solid white; */
  position: relative;
  flex-grow: 0.35;
}

#wargames_keyboard .btn span {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
 
#wargames_keyboard .btn:last-child {
  flex-grow: 1;
}
